const TYPES = ["email", "phone", "google", "facebook", "apple"] as const;

// Unfortunately, this doesn't work with SSR :(
// const useRegistrationType = () => useStorage<typeof TYPES[number] | "">("typeRegistration", "");
const useRegistrationType = () =>
	computed<(typeof TYPES)[number] | "">({
		get: () => {
			if (typeof window !== "undefined") {
				return localStorage.getItem("typeRegistration") as (typeof TYPES)[number] | "";
			}
			return "";
		},
		set: (value: (typeof TYPES)[number] | "") => {
			if (typeof window !== "undefined") {
				localStorage.setItem("typeRegistration", value);
			}
		}
	});

export default useRegistrationType;
